import React from "react";
import styled from "styled-components";

const Style = styled.div `
  h1 {
    color: ${(props) => props.theme.colorSpecial};
    font-family: ${(props) => props.theme.fontSpecial};
    text-align: center;
    margin-bottom: 20px;
    font-size: 500%;
  }
`;

const Title = (props) => {
    return (
      <Style>
        <h1>{props.title}</h1>     
      </Style>
    );
  };
  
  export default Title;