import React from "react"
import Layout from "../components/layout"
import Title from "../components/common/Title.jsx"
import { ToastContainer, toast } from "react-toastify"
import Form from "react-bootstrap/Form"
import { Row, Col, Button } from "react-bootstrap"
import "react-toastify/dist/ReactToastify.css"

toast.configure()

export default class ContactUs extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      sendenButtonText: "Abschicken",
      submitButtonDisabled: false,
      name: "",
      email: "",
      content: "",
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleNameChange = this.handleNameChange.bind(this)
    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handleContentChange = this.handleContentChange.bind(this)
  }

  notifySuccess = () =>
    toast.success(
      "🎉🎉 Danke für deine Nachricht, wir werden uns sobald wie möglich bei dir melden 🎉🎉",
      {
        containerId: "success",
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      }
    )
  notifyfailed = () =>
    toast.error(
      "😖😖 Sorry da ist etwas schief gelaufen, bitte versuche es später noch einmal 😖😖 ",
      {
        containerId: "failed",
        position: "top-right",
        autoClose: 4000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      }
    )

  handleNameChange(event) {
    this.setState({ name: event.target.value })
  }

  handleEmailChange(event) {
    this.setState({ email: event.target.value })
  }

  handleContentChange(event) {
    this.setState({ content: event.target.value })
  }

  handleSubmit(event) {
    event.preventDefault()
    this.setState({ sendenButtonText: "Sendet...", submitButtonDisabled: true })
    const payload = {
      email: this.state.email,
      name: this.state.name,
      content: this.state.content,
    }

    this.sendMail(payload, (err, res) => {
      if (err) {
        return this.error(err)
      }
      this.success()
    })
  }

  sendMail(body, callback) {
    const url =
      "https://3q47nkhtl9.execute-api.eu-central-1.amazonaws.com/dev/email/send"

    let req = new XMLHttpRequest()
    req.open("POST", url, true)
    req.setRequestHeader("Content-Type", "application/json")
    req.addEventListener("load", function() {
      if (req.status < 400) {
        callback(null, JSON.parse(req.responseText))
      } else {
        callback(new Error("Request failed: " + req.statusText))
      }
    })
    const data = JSON.stringify(body)
    console.log(data)
    req.send(data)
  }

  error(err) {
    this.setState({
      submitButtonDisabled: false,
    })
    this.notifyfailed()
    console.log(err)
  }

  success() {
    this.setState({
      submitButtonDisabled: false,
      name: "",
      email: "",
      content: "",
      sendenButtonText: "Abschicken",
    })
    this.notifySuccess()
  }

  render() {
    return (
      <Layout>
        <ToastContainer
          containerId={"success"}
          position="top-right"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <ToastContainer />
        <ToastContainer
          containerId={"failed"}
          position="top-right"
          autoClose={4000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
        <ToastContainer />
        <Title title="Contact us" />
        <p>
          Du möchtest mehr über uns erfahren, hast hilfreiche Tipps für uns oder
          willst ein paar Tipps von uns? Dann schreib uns doch einfach 😁
        </p>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col>
              <Form.Label>
                <strong>Name</strong>
              </Form.Label>
              <Form.Control
                value={this.state.name}
                onChange={this.handleNameChange}
                type="text"
                placeholder="max mustermann"
                required
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label className="mt-2">
                <strong>Email</strong>
              </Form.Label>
              <Form.Control
                value={this.state.email}
                onChange={this.handleEmailChange}
                type="email"
                placeholder="maxmustermann@mail.com"
                required
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Label className="mt-3">
                <strong>Deine Nachricht an uns</strong>
              </Form.Label>
              <Form.Control
                value={this.state.content}
                onChange={this.handleContentChange}
                as="textarea"
                rows="10"
                placeholder="..."
                required
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Button
                className="mt-2 mb-2"
                disabled={this.state.submitButtonDisabled}
                variant="primary"
                type="submit"
              >
                {this.state.sendenButtonText}
              </Button>
            </Col>
          </Row>
        </Form>
      </Layout>
    )
  }
}
